



































import { Vue, Component, Prop } from 'vue-property-decorator'
import { Meeting } from '@tada-team/tdproto-ts'
import { rootStore } from '@/store'

@Component({
  components: {
    BaseCallStatusIcon: () => import('@/components/UI/BaseCallStatusIcon.vue'),
    IconPhone: () => import('@/components/UI/icons/IconPhone.vue'),
    IconMessageSquare: () => import('@/components/UI/icons/IconMessageSquare.vue'),
    IconPhoneCall: () => import('@/components/UI/icons/IconPhoneCall.vue'),
  },
})
export default class MeetingCardInterval extends Vue {
  @Prop({
    required: true,
    type: Object,
  }) meeting!: Meeting

  @Prop({
    required: false,
    type: String,
    default: 'secondary',
  }) type!: string

  @Prop({
    required: false,
    type: Boolean,
    default: false,
  }) readonly narrowMode!: boolean

  @Prop({
    required: false,
    type: Boolean,
    default: false,
  }) readonly isShowInterval!: boolean

  @Prop({
    required: false,
    type: Boolean,
    default: false,
  }) readonly isShowIcon!: boolean

  @Prop({
    required: false,
    type: Boolean,
    default: false,
  }) readonly isShowDay!: boolean

  private formatter = new Intl.DateTimeFormat(this.$i18n.locale, {
    day: '2-digit',
    month: 'short',
  })

  get timeInterval () {
    const start = new Date(this.meeting.startAt)
    const end = new Date(start.getTime() + this.meeting.duration * 60000)
    const startInterval = start.toLocaleString(this.$i18n.locale, {
      minute: '2-digit',
      hour: '2-digit',
    })
    const endInterval = end.toLocaleString(this.$i18n.locale, {
      minute: '2-digit',
      hour: '2-digit',
    })
    return `${startInterval} — ${endInterval}`
  }

  get date () {
    return this.formatter.format(new Date(this.meeting.startAt))
  }

  get callStatus () {
    return rootStore.getters.callStatuses[this.meeting.jid]
  }
}
