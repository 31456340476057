var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'row items-center': !_vm.narrowMode,
    'column reverse items-start justify-end full-width ellipsis': _vm.narrowMode,
  }},[(_vm.isShowInterval)?_c('q-item-section',{staticClass:"q-mr-sm",class:{
      'meeting-card-interval__secondary': _vm.type === 'secondary',
      'meeting-card-interval__primary': _vm.type === 'primary',
      'meeting-card-interval__sm full-width': _vm.narrowMode,
      'meeting-card-interval__md': !_vm.narrowMode,
    },attrs:{"top":"","no-wrap":""}},[_c('q-item-label',{attrs:{"lines":"1"}},[(_vm.isShowDay)?_c('b',[_vm._v(_vm._s(_vm.date))]):_vm._e(),_vm._v(" "+_vm._s(_vm.timeInterval)+" ")])],1):_vm._e(),(_vm.callStatus)?_c('q-icon',{attrs:{"color":"base-brand"}},[_c('BaseCallStatusIcon',{attrs:{"status":_vm.callStatus}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }